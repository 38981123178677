function formatOrganization({
  slug,
  type,
  name,
  // Shared Configs
  restrictRegister, // Users must be invited

  // Community (apprentage)
  enableWorkforceSuite,

  // Enterprise (workforce)
  enableProjects,
  integrateMicrosoftTeams,

  // Stripe
  integration
}) {
  const data = {
    // Default Configs
    enableNewEditor: { 'en-US': true },
    // Can NOT integrate company's Slack Channel
    integrateSlack: { 'en-US': false },
    // Can NOT sell courses
    billing: { 'en-US': false },
    // Flags : FALSE
    enablePathways: { 'en-US': false },
    // Flags : TRUE
    enablePages: { 'en-US': true },
    enableResources: { 'en-US': true },
    enableCourseCatalog: { 'en-US': true },
    enableUserDirectory: { 'en-US': true },
    enableUserGroups: { 'en-US': true },
    enableVelaMaterials: { 'en-US': true }
  };

  if (slug !== undefined) {
    data.slug = {
      'en-US': slug
    };
  }

  if (type !== undefined) {
    data.type = {
      'en-US': type
    };
  }

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  // SHARED CONFIGS
  if (restrictRegister !== undefined) {
    data.restrictRegister = {
      'en-US': restrictRegister
    };
  }

  // COMMUNITY (apprentage)
  if (enableWorkforceSuite !== undefined) {
    data.enableWorkforceSuite = {
      'en-US': enableWorkforceSuite
    };
  }

  // ENTERPRISE (workforce)
  if (enableProjects !== undefined) {
    data.enableProjects = {
      'en-US': enableProjects
    };
  }

  if (integrateMicrosoftTeams !== undefined) {
    data.integrateMicrosoftTeams = {
      'en-US': integrateMicrosoftTeams
    };
  }

  // STRIPE INTEGRATION
  if (integration !== undefined) {
    data.integration = {
      'en-US': integration
    };
  }

  return { fields: { ...data } };
}

export default formatOrganization;
